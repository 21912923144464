<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div v-if="(id > 0)" class="col-sm-6">
                        <h1>{{ $t('general.operario') }}</h1>
                    </div>
                    <div v-else class="col-sm-6">
                        <h1>{{ $t('general.nuevooperario') }}</h1>
                    </div>
                    <div class="col-sm-6">
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div v-if="(id > 0)" class="col-md-2">
                    <operario-foto :id="id" :operario="operario" />
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <ul class="list-group list-group-unbordered mb-3">
                               <!-- <li class="list-group-item">
                                    <b>{{ $t('general.expedientesactivos') }}</b> <a class="float-right">5</a>
                                </li>-->
                                <li class="list-group-item">
                                    <b>{{ $t('general.visitaspendientes') }}</b> <a
                                        class="float-right">{{operario.siguientes_citas}}</a>
                                </li>
                                <!-- <li class="list-group-item">
                                    <b>{{ $t('general.visitascaducadas') }}</b> <a class="float-right">1</a>
                                </li>-->
                            </ul>
                        </div>
                    </div>
                    <div class="callout callout-info">
                        <h5>{{ $t('general.ultimamodificacion') }}</h5>
                        <p>{{ operario.fecha_actualiza_operario }}</p>
                    </div>
                    <div class="callout callout-success">
                        <h5>Sub-operarios</h5>
                        <div v-if="operjefe!=''">
                            <label>Operario jefe:</label>
                            <div class="row" style="justify-content: space-between;">
                                <span>{{ operjefe.nombre }}</span>
                                <button class="btn btn-sm btn-danger" @click="eliminarjefe">Eliminar</button>
                            </div>
                        </div>
                        <div class="form-group" v-else-if="haysubos == false">
                            <label for="opers">Sub-operario de:</label>
                            <select class="form-control" v-model="suboperselec" id="opers">
                                <option value="-1">Sin operario jefe</option>
                                <option v-for="operario in operarios" :key="operario.idoperario" :value="operario.idoperario">{{ operario.nombre }}</option>
                            </select>
                        </div>
                        <div v-else-if="haysubos == true">
                            <label for="">Sub-operarios:</label>
                            <table>
                                <tbody>
                                    <tr v-for="suboperario in suboperarios" :key="suboperario.id_relacion_suboperarios">
                                        <td>{{ suboperario.nombre_operario_suboperario }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-10">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#operario_datos"
                                        data-toggle="tab">{{ $t('general.datos') }}</a></li>
                                <li class="nav-item" v-if="gestion"><a class="nav-link" href="#operario_marcas"  data-toggle="tab">{{
                                        $t('general.marcas') }}</a></li>
                                <li class="nav-item" v-if="gestion || documentacionoperarios"><a class="nav-link"  href="#operario_docs" data-toggle="tab">{{
                                        $t('general.docrequerida') }}</a></li>
                                <li class="nav-item" v-if="gestion"><a class="nav-link" href="#operario_tipos_servicio"
                                        data-toggle="tab">{{ $t('general.tiposservicio') }}</a></li>
                                <li v-if="operariosporcomp" class="nav-item"><a class="nav-link" href="#operario_companias" data-toggle="tab">{{
                                        $t('general.companias') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#operario_especialidades"
                                        data-toggle="tab">{{ $t('general.especialidades') }}</a></li>
                                <li v-if="operszonacp" class="nav-item"><a class="nav-link" href="#operario-codigos-postales"
                                        data-toggle="tab">Códigos postales</a></li>
                                <li class="nav-item"><a class="nav-link" href="#operario_parametros"
                                    data-toggle="tab">Parámetros</a></li>                                  
                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="tab-content">
                        <div class="active tab-pane" id="operario_datos">
                            <operario-datos v-if="permisoscargados" :id="id" :operario="operario" :permisomodif="permisomodif"/>
                        </div>
                        <div v-if="gestion && permisoscargados && permisomodif" class="tab-pane" id="operario_marcas">
                            <operario-marcas  :id="id" :operario="operario" :permisomodif="permisomodif"/>
                        </div>
                        <div v-if="(gestion || documentacionoperarios) && permisoscargados && permisomodif" class="tab-pane" id="operario_docs">
                            <operario-documentacion  :id="id" :operario="operario" :permisomodif="permisomodif"/>
                        </div>
                        <div v-if="gestion && permisoscargados && permisomodif" class="tab-pane" id="operario_tipos_servicio">
                            <operario-tiposservicio  :id="id" :operario="operario" :permisomodif="permisomodif"/>
                        </div>
                        <div v-if="operariosporcomp && permisoscargados" class="tab-pane" id="operario_companias">
                            <operario-companyias :id="id" :operario="operario" :permisomodif="permisomodif"/>
                        </div>
                        <div class="tab-pane" v-if="permisoscargados" id="operario_especialidades">
                            <operario-especialidades :id="id" :operario="operario" :permisomodif="permisomodif"/>
                        </div>
                        <div v-if="operszonacp && permisoscargados" class="tab-pane" id="operario-codigos-postales">
                            <operario-codigos-postales :id="id" :operario="operario" :permisomodif="permisomodif"/>
                        </div>
                        <div class="tab-pane" v-if="permisoscargados" id="operario_parametros">
                            <operario-parametros :id="id" :operario="operario" :permisomodif="permisomodif"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi'
import OperarioDatos from './datos/OperarioDatos.vue'
import OperarioFoto from './OperarioFoto.vue'
import OperarioMarcas from './Marcas/OperarioMarcas.vue'
import OperarioTiposServicio from './TiposServicio/OperarioTiposServicio.vue'
import OperarioCompanyias from './compañias/OperarioCompanyias.vue'
import OperarioDocumentacion from './documentacion/OperarioDocumentacion.vue'
import OperarioEspecialidades from './especialidades/OperarioEspecialidades.vue'
import OperarioCodigosPostales from './codigospostales/OperarioCodigosPostales.vue'
import OperarioParametros from './parametros/OperarioParametros.vue'
//import Dialog from 'primevue/dialog';
export default {
    props: ['id'],
    components: {
        'operario-datos': OperarioDatos,
        'operario-foto': OperarioFoto,
        'operario-marcas': OperarioMarcas,
        'operario-tiposservicio': OperarioTiposServicio,
        'operario-companyias': OperarioCompanyias,
        'operario-documentacion': OperarioDocumentacion,
        'operario-especialidades': OperarioEspecialidades,
        'operario-codigos-postales':OperarioCodigosPostales,
        'operario-parametros': OperarioParametros,
    },
    data() {
        return {
            operariosporcomp:false,
            documentacionoperarios:false,
            gestion:false,
            operjefe:'',
            suboperselec:'',
            operario: [],
            operarios: [],
            suboperarios: [],
            haysubos:null,
            operszonacp:false,
            permisomodif:false,
            permisoscargados:false,
        }
    },
    methods: {
        eliminarjefe(){
            this.suboperselec = -1;
        },
        async obtenerOperario(id) {
            /* get  modulos/pwgsapi/index.php/operarios/:id */  
            this.operjefe = '';          
            const api = new PwgsApi;
            const resp = await api.get('operarios/simple');
            this.operarios = resp.datos;
            this.operarios = this.operarios.filter(operario=> operario.idoperario != this.id);
            const datos = await api.get('operarios/' + id);
            this.operario = datos.datos;
            //
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let nombreoperario = this.operario.nombre;
            
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/operarios/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = nombreoperario;
                    this.$store.getters.ventanas[i].alt = "Operario: " + this.operario.nombre + " || " + this.operario.poblacion_operario;
                }
            }
            this.obtenerSubOperarios();
            //
        },
        async obtenerSubOperarios(){
            const api = new PwgsApi;
            const resp = await api.get('operarios/'+this.id+'/suboperarios');
            if(resp.operario_principal !=''){                
                this.operjefe = this.operarios.find(operario=> operario.idoperario == resp.operario_principal);
                console.log('operje', this.operjefe, this.operarios);
            }else{
                if(resp.suboperarios.datos){
                    this.suboperarios = resp.suboperarios.datos;
                    this.haysubos = true;
                }else{
                    this.suboperselec = '-1';
                    this.haysubos = false;
                }
            }
            console.log('subops', this.suboperarios);
        },
        async cambiarSubOpeario(newval, oldval){
            const api = new PwgsApi;
            console.log('newv', newval);
            if(newval == '-1'){
                if(oldval !=''){
                    const resp = await api.delete('operarios/'+oldval+'/suboperarios/'+this.id);
                    console.log('resp', resp);
                }else{
                    if(this.operjefe !=''){
                        const resp = await api.delete('operarios/'+this.operjefe.idoperario+'/suboperarios/'+this.id);
                        console.log('respa', resp);
                        this.operjefe = '';
                    }
                    this.haysubos = false;
                    console.log('estabavacio');
                }
            }else{
                if(oldval !='-1'){
                    await api.delete('operarios/'+oldval+'/suboperarios/'+this.id);
                }
                await api.post('operarios/'+newval+'/suboperarios', {id_suboperario: this.id});
                this.operjefe = this.operarios.find(operario=> operario.idoperario == newval);
            }
        },
        async comprobarPermisos(){
            const api = new PwgsApi();
            const ges = await api.get('parametros-pwgs/gestion_de_equipos');
            if(ges.gestion_de_equipos == 1){
                this.gestion = true;
            }
            const docopers = await api.get('parametros-pwgs/documentacion_operarios');
            if(docopers.documentacion_operarios == 1){
                this.documentacionoperarios = true;
            }
            const operscomp = await api.get('parametros-pwgs/operarios_por_companyia');
            if(operscomp.operarios_por_companyia == 1){
                this.operariosporcomp = true;
            }
            const zonacp = await api.get('parametros-pwgs/operario_zona_cp');
            if(zonacp.operario_zona_cp == 1){
                this.operszonacp = true;
            }
            const modificar = await api.get('operarios/permiso-modificar');
            if(modificar == 1){
                console.log('entraasasas');
                this.permisomodif = true;
            }
            this.permisoscargados = true;
        },
    },
    mounted() {
        this.obtenerOperario(this.id);
        this.comprobarPermisos();
    },
    watch: {
        id() {
            this.obtenerOperario(this.id);
        },
        $route() {
            //
            console.log('llegaroute', this.id);
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let nombreoperario = this.operario.nombre;
     
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/operarios/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = nombreoperario;
                    this.$store.getters.ventanas[i].alt = "Operario: " + this.operario.nombre + " || " + this.operario.poblacion_operario;
                }
            }
            //
        },
        suboperselec(newval,oldval) {
            console.log('oldval', newval, oldval);
            this.cambiarSubOpeario(newval, oldval);
        }
    }
}
</script>
